.analytics-wrapper{
    width: 100%;
    height: 1000%;
}
.analytics{
    display: block;
    max-width:100%;
    width: 100%;
    max-height: 100%;
    overflow: auto !important;
    margin: auto !important;
    position: relative !important;
    
}
.interactions-chart{
    float: left !important;
    padding-right: 2vw;
}
.filterButton{
    width: 50px;
    margin: auto !important;
}

.right-table{
 width: 55%;
 float:right !important;
 margin-right: 20px !important;
}
.filteredTable{
   width: 100%;
   margin-right: 20px !important;
}

.notice{
    margin-left: 35% !important;
    margin-top: 10px;
    color: darkcyan;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;  
}

.chart {
    padding:1vh;
}