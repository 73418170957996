.card-container {
    margin: 20px auto;
    width: 80%;
    max-width: 600px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background: #fff;
}

.card-content {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    background: #f5f5f5;
    padding-bottom: 72px;
    width: 100%;
}

.date-picker-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 5%;
    
}


.send-button {
    width: 100%;
    padding: 12px 0;
    font-weight: bold; 
    font-size: 18px;
    border-radius: 4px;
    color: white; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s; 
}

.send-button:hover {
    background-color: #5e7191;
}

.box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3%;
    gap: 20px;
    
}

.proactivity-box {
    display: flex;
    width: 100%;
    justify-content:center;
    flex-direction: column;
    margin-top: -2%;
}

.checkbox {
    margin-bottom: -9%;
    margin-top:1%;
}

.sliderTitle {
    margin-bottom: -5%;
}

.complexity {
    margin-top: 5%;
    margin-bottom: 3%;
}

.slider {
    width:auto;
    margin-left: 3%;
    margin-right: 3%;
}

.proactivity-stack {
    justify-content:center;
    margin-top: 2%;
    margin-bottom:2%;
}

.selected-item {
    background-color: rgba(0, 0, 0, 0.3) !important;
    color: black !important;
}
  
.selected-item:hover {
    background-color: rgba(0, 0, 0, 0.2) !important;
}