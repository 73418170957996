.animated-word {
  display: inline-block;
  position: relative;
  animation: drift 0.75s ease-out forwards;
  opacity: 0;
}

.animated-word-complete {
  display: inline-block;
  position: relative;
}

@keyframes drift {
  0% {
    transform: translate(0, 0) scale(0.1);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}
