.rbc-time-view {
    .rbc-label {
    display: none;
    }
    .rbc-allday-cell {
    height: calc(100vh - 98px);
    max-height: unset;
    }
    .rbc-time-content {
    display: none;
    }
    }

:root {
    --service-color: #1F6AC0;
    --media-color: #003777;
    --all-color: #002743;
    --general-color: #87bee8;
    --in-progress: #dee461;
    --completed: #87e889;
    --cancelled: #8fc9f6;
    --participation: #dadada;
        /* Define more custom colors here */
}
  