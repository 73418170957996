.mainApp {
  display: flex;
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 70px;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
