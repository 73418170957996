.rich-text-editor-quill-component {
  font-family: 'Roboto', sans-serif;
}

.uneditable-quill-component .ql-editor {
  padding: 0;
}

.rich-text-editor-label {
  margin-bottom: 15px;
}

.error-label {
  color: #d32f2f;
}

.error-quill-component {
  border: 1px solid #d32f2f;
}
